import 'leaflet/dist/leaflet.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button } from 'app/components/Common/Button'
import { CapitalLetter } from 'app/components/Icons'
import { useVocabularyData } from 'app/utils/vocabulary'
import L from 'leaflet'
import React, { memo, useEffect, useRef, useState } from 'react'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'

export interface Props {
  address?: string
  googleMapsURL?: string
  languageCode: string
  longitude?: string
  latitude?: string
  title?: string
}

export const Map = memo(function Map({
  address,
  googleMapsURL,
  languageCode,
  longitude,
  latitude,
  title,
}: Props) {
  const map = useRef(null)
  const [markerIcon, setMarkerIcon] = useState(L.divIcon)

  const bounds = [] as any

  bounds.push([latitude, longitude])

  useEffect(() => {
    setMarkerIcon(
      L.divIcon({
        iconSize: [84, 94],
        iconAnchor: [42, 94],
        html: `<img src="/map_marker.svg" alt="Terme di Stigliano" width="70" height="80">`,
        className: 'main-icon',
      }),
    )
  }, [])

  return (
    <Container>
      {title ? (
        <FadeInUp>
          <TitleContainer>
            <CapitalLetter />
            <Title dangerouslySetInnerHTML={{ __html: title }} />
          </TitleContainer>
        </FadeInUp>
      ) : null}
      {address ? (
        <FadeInUp>
          <Address dangerouslySetInnerHTML={{ __html: address }} />
        </FadeInUp>
      ) : null}

      {typeof window !== undefined && latitude && longitude ? (
        <LeafletMap
          center={[Number(latitude), Number(longitude)]}
          dragging={L.Browser && L.Browser.mobile ? false : true}
          zoom={10}
          scrollWheelZoom={false}
          whenCreated={(mapInstance: any) => {
            map.current = mapInstance
            mapInstance.fitBounds(bounds)
            mapInstance.setZoom(10)
          }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
          />
          <Marker
            icon={markerIcon}
            position={[Number(latitude), Number(longitude)]}
          />
        </LeafletMap>
      ) : null}
      {googleMapsURL ? (
        <StyledButton
          label={useVocabularyData('open-google-maps', languageCode)}
          URL={googleMapsURL}
          rel="noreferrer"
          target="_blank"
          variant="dark"
        />
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  top: -4.5vw;

  @media (max-width: 1199px) {
    padding-top: 0;
    top: 0;
  }

  @media (max-width: 767px) {
    padding-top: 2.5rem;
  }
`

const TitleContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 14.75vw;
    height: 18.75vw;

    @media (max-width: 1199px) {
      width: 9rem;
      height: 11.4375rem;
    }
  }
`

const Title = styled.h2`
  position: absolute;
  top: 57%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: normal;
  font-size: 5.31vw;
  line-height: 1.058;
  text-align: center;

  @media (max-width: 1199px) {
    font-size: 3.4375rem;
  }

  strong {
    color: ${({ theme }) => theme.colors.variants.neutralLight3};
    font-weight: unset;
    font-size: 3.43vw;

    @media (max-width: 1199px) {
      font-size: 1.5625rem;
    }
  }
`

const Address = styled.div`
  margin-top: 1.25rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: normal;
  font-size: 1.06vw;
  line-height: 1.64;
  text-align: center;

  @media (max-width: 1199px) {
    margin-top: 1.25rem;
    font-size: 0.8125rem;
  }
`

const LeafletMap = styled(MapContainer)`
  width: 100%;
  height: 45.5vw;
  min-height: 25rem;
  margin-top: 5.62vw;

  @media (max-width: 1199px) {
    margin-top: 3.75rem;
  }

  .leaflet-pane {
    z-index: 1 !important;
  }

  .leaflet-left {
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;
    z-index: 1 !important;

    .leaflet-control {
      border: 0;
      border-radius: 0;
      margin: 0;

      a {
        position: relative;
        width: 3.4375rem;
        height: 3.4375rem;
        background: ${({ theme }) => theme.colors.variants.neutralLight1};
        border: 0;
        border-radius: 0% !important;
        line-height: 2.875rem;
        text-indent: -624.9375rem;
        transition: 0.2s ease-out;

        &.leaflet-control-zoom-in,
        &.leaflet-control-zoom-out {
          &:hover {
            background: ${({ theme }) => theme.colors.variants.primaryDark};

            &:before,
            &:after {
              background: ${({ theme }) => theme.colors.variants.neutralLight1};
            }
          }

          &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 1.125rem;
            height: 0.125rem;
            background: ${({ theme }) => theme.colors.variants.primaryDark};
            transition: 0.2s ease-out;
            transform: translate(-50%, -50%);
          }
        }

        &.leaflet-control-zoom-in {
          &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 0.125rem;
            height: 1.125rem;
            background: ${({ theme }) => theme.colors.variants.primaryDark};
            transition: 0.2s ease-out;
            transform: translate(-50%, -50%);
          }
        }

        &.leaflet-disabled {
          opacity: 0.2;
          pointer-events: none;

          &:hover {
            opacity: 0.2;
          }
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .leaflet-bottom {
    display: none;
  }
`

const StyledButton = styled(Button)`
  position: absolute;
  left: 3.12vw;
  bottom: 2.81vw;
  z-index: 1;

  @media (max-width: 1199px) {
    left: 1.5625rem;
    bottom: 1.5625rem;
  }
`
